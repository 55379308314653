import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ObtainJWTService {

  protected coreAPIPath = 'https://demo.bluescan.it/local/docIDRest/rest';

  constructor(protected httpClient: HttpClient) { }

  obtainJWT(sessionId: string, otp: string) {

    let params = new HttpParams().set('otp', otp);

    let url = this.coreAPIPath + '/sessions/' + sessionId + '/accessToken'

    return this.httpClient.get(url, { params: params });

  }


}
