/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GlobalCrossCheck } from '../model/globalCrossCheck';
import { LocalCrossCheck } from '../model/localCrossCheck';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CrossCheckControllerService {

    protected basePath = 'https://demo.bluescan.it/local/webapp-api/rest' //'http://localhost:9085/local/webapp-api/rest';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }

        this.defaultHeaders.set("Authorization", "Bearer " + this.getCookie("JWTTOKEN")); // Prende il token JWT dai cookie
    }

    private getCookie(name: string){
        var pattern = RegExp(name + "=.[^;]*")
        var matched = document.cookie.match(pattern)
        if(matched){
            var cookie = matched[0].split('=')
            return cookie[1]
        }
        return false
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GET local crosscheck
     * Servizio che restituisce i dati relativi al local crosscheck.
     * @param sessionId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentCrossCheck(sessionId: string, documentId: number, observe?: 'body', reportProgress?: boolean): Observable<LocalCrossCheck>;
    public getDocumentCrossCheck(sessionId: string, documentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LocalCrossCheck>>;
    public getDocumentCrossCheck(sessionId: string, documentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LocalCrossCheck>>;
    public getDocumentCrossCheck(sessionId: string, documentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getDocumentCrossCheck.');
        }

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getDocumentCrossCheck.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        headers = new HttpHeaders().set("Authorization", "Bearer " + this.getCookie("JWTTOKEN"));

        return this.httpClient.request<LocalCrossCheck>('get',`${this.basePath}/sessions/${encodeURIComponent(String(sessionId))}/documents/${encodeURIComponent(String(documentId))}/crossCheck`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GET global crosscheck
     * Servizio che restituisce i dati relativi al global crosscheck.
     * @param sessionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGlobalCrossCheck(sessionId: string, observe?: 'body', reportProgress?: boolean): Observable<GlobalCrossCheck>;
    public getGlobalCrossCheck(sessionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GlobalCrossCheck>>;
    public getGlobalCrossCheck(sessionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GlobalCrossCheck>>;
    public getGlobalCrossCheck(sessionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sessionId === null || sessionId === undefined) {
            throw new Error('Required parameter sessionId was null or undefined when calling getGlobalCrossCheck.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        headers = new HttpHeaders().set("Authorization", "Bearer " + this.getCookie("JWTTOKEN"));

        return this.httpClient.request<GlobalCrossCheck>('get',`${this.basePath}/sessions/${encodeURIComponent(String(sessionId))}/crossCheck`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
